/* eslint-disable */
<template>
  <div class="container">
    <div class="first-page">
      <div class="back-btn-container">
        <a id="dsb_url" href="#">
          <div id="back_btn">
              <img class="btn_icon" src="@/assets/dashboard2.png" alt="back_btn" width="30px" />
              <h2>Dashboard</h2>
          </div>
        </a>
      </div>
    
      <h1>Chat with any PDF</h1>

      <div class="drag-box-main">
        <!-- Start loader div -->
        <div class="loader-overlay" v-if="loading">
          <img src="@/assets/images/upload_pdf_icon.png" alt=""/>
          <span class="upload-text-per">{{ progress + '%' }}</span>
          <div class="progress-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
          </div>
          <p class="upload-text">{{ uploadText }}</p>
        </div>
  
        <!-- End loader div -->
        <div class="drag-box" @dragover="dragover" @dragleave="dragleave" @drop="drop">
          <input type="file" id="file-selector" single class="input-file" @change="onChange" ref="file" accept=".pdf"/>
          <label for="assetsFieldHandle" class="block cursor-pointer">
            <div class="drag-text" @click="showBrowseComputer" v-if="!loading">
              <img src="@/assets/images/upload_pdf_icon.png" alt=""/>
              <br>
              <span>Drag & Drop PDF Here</span>
            </div>
          </label>
          <div class="other-options">
            <div class="left" @click="showBrowseComputer">Browse Computer</div>
            <div class="right" @click="showModal">My Documents</div>
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-box-main">
      <div class="title-box">
        <div class="left seventy">
          <div class="img-box">
            <img src="@/assets/images/chatpdf_logo.png" alt="ChatPDF Logo" />
          </div>
          <span class="label_desktop">
            <span v-html="filename_tuncated_dektop"></span>
          </span>
          <span class="label_tab">
            <span v-html="filename_tuncated_tab"></span>
          </span>
          <span class="label_mobile">
            <span v-html="filename_tuncated_mobile"></span>
          </span>
        </div>
        <div class="right twenty">
          <div class="clsExport" @click="onClickExportButton">
            <div class="ToolbarButtonExport" id="btn_export">
              <svg
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z" />
              </svg>
            </div>
            <div class="clsExportText">
              Export Conversation
            </div>
          </div>
          <div class="clsCloseX">
            <span @click="closePDF">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.9 19.5L5.5 18.1L11.1 12.5L5.5 6.9L6.9 5.5L12.5 11.1L18.1 5.5L19.5 6.9L13.9 12.5L19.5 18.1L18.1 19.5L12.5 13.9L6.9 19.5Z" fill="black" fill-opacity="0.5"/>
              </svg>
            </span>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="result-box" id="result-box" ref="result-box">
      </div>
      <div class="prompt-box">
        <input type="text" class="prompt" maxlength="500" placeholder="Ask any question..."
               v-on:keyup.enter="searchFile">
        <button class="submit" @click="searchFile">
          <span role="img" aria-label="send" class="">
            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6.81034L19 1.5L12.8065 15.5L10.7419 9.22414L3 6.81034Z" fill="white"/>
            <path d="M19 1.5L3 6.81034L10.7419 9.22414M19 1.5L12.8065 15.5L10.7419 9.22414M19 1.5L10.7419 9.22414" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
            </svg>

          </span>
        </button>
      </div>
    </div>
    <div class="footer" v-show="isFooterVisible">
      <a href="https://ai-pro.org/disclaimer/" class="disclaimer" target="_blank" rel="noreferrer">
          We are neither affiliated nor related to OpenAI.
      </a>
    </div>
    <Modal v-show="isModalVisible" @close="closeModal" @loadDocument="loadDocument" @deleteDocument="deleteDocument"/>
    <ModalDelete v-show="isModalDeleteConfirmVisible" @close="closeModalDelete" @confirmDeleteDocument="confirmDeleteDocument" :docToDelete="docToDelete"/>

    <div class="loader-box">      
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CookieHelper from "@/helpers/cookie-helper";
import axios from 'axios';
import $ from "jquery";
import Modal from '@/components/Modal.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import { encodingForModel } from "js-tiktoken";

export default {
  name: 'ChatPDF',
  components: {Modal, ModalDelete},

  data() {
    return {
      filelist: [],
      file: null,
      filename: '',
      filename_tuncated_dektop: '',
      filename_tuncated_tab: '',
      filename_tuncated_mobile: '',
      filestatus: '',
      userid: '',
      temperature: '',
      template: '',
      paramResponse: '',
      paramRowID: '',
      typeWriterCounter: 0,
      mydocuments: [],
      isModalVisible: false,
      isModalDeleteConfirmVisible: false,
      aiwp_logged_in: '',
      docToDelete: '',
      showDisclaimer: false,
      mode: 'live',
      userIp: '',
      progress: 0,
      uploadText: "Uploading...",
      isFileExist: false,
      isFooterVisible: true,
    }
  },

  async created() {

    const urlParams = new URLSearchParams(window.location.search);
    let mode = urlParams.get('mode');
    let temperature = process.env.VUE_APP_TEMPERATURE ? process.env.VUE_APP_TEMPERATURE : '0.2';
    let template =  process.env.VUE_APP_TEMPLATE ? process.env.VUE_APP_TEMPLATE : '1'; ;

    if (mode!=null && mode!=''){
      CookieHelper.setCookie("mode", mode, 30);
    }
    if (temperature!=null && temperature!=''){
      CookieHelper.setCookie("temperature", temperature, 30);
    }
    if (template!=null && template!=''){
      CookieHelper.setCookie("template", template, 30);
    }

    this.userid = CookieHelper.getFlag("userid", "");
    this.mode = CookieHelper.getFlag("mode", "live");

    if (this.mode==='test'){
      this.temperature = CookieHelper.getFlag("temperature", temperature);
      this.template = CookieHelper.getFlag("template", template);
    }else{
      this.temperature = temperature;
      this.template = template;
    }

    console.log("mode:",this.mode,"temperature",this.temperature,"template:",this.template);

    let intialUsage = sessionStorage.getItem('tried_remote');
    const limitedUsage = sessionStorage.getItem('WcvYPABR');
    sessionStorage.setItem("intialUsage", intialUsage);

    if (this.userid == '') {
      this.userid = Math.floor(100000000 + Math.random() * 900000000);
      CookieHelper.setCookie("userid", this.userid, 30);
    }

    let aiwp_logged_in =  CookieHelper.getCookie("aiwp_logged_in");
    if (aiwp_logged_in!==undefined && aiwp_logged_in !== ''){
      console.log("ai:",aiwp_logged_in);
      CookieHelper.setCookie("aiwp_info", aiwp_logged_in, 30);
    }

    setTimeout(function(){
      this.aiwp_logged_in = CookieHelper.getCookie('user_email');
      if (this.aiwp_logged_in!='' && this.aiwp_logged_in.indexOf('free') == -1 && !(window.self !== window.top)){
        $('#back_btn').show();
      }
      console.log("user",this.aiwp_logged_in)
    }, 2000);

  },
  async mounted() {
    this.initData();
    this.changeUrl();
    this.$nextTick(() => {
      if (window.top !== window.self) this.isFooterVisible = false;
    });

    $('#back_btn').hide();

    var perfEntries = performance.getEntriesByType("navigation");
    if (perfEntries[0].type === "back_forward") {
        location.reload();
    }

    this.userIp = await this.getUserIP();
  },

  methods: {
    simulateProgress(percentage) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.progress >= percentage || this.progress >= 100) {
            clearInterval(interval); 
            resolve(this.progress);   
          }

          if (this.progress >= 1 && this.progress <= 50) {
            this.uploadText = "Uploading";
          } else if (this.progress >= 51 && this.progress <= 91) {
            this.uploadText = "Analyzing";
          } else if (this.progress >= 92 && this.progress <= 100) {
            this.uploadText = "Processing";
          }

          this.progress += 1;  
        }, 50); 
      });
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    scrollToBottom() {
      if (this.$refs['result-box']) {
        this.$refs['result-box'].scrollTop = this.$refs['result-box'].scrollHeight;
      } else {
        console.error("Ref 'result-box' not found.");
      }
    },

    onChange() {
      console.log(this.filelist)
      this.filelist = [this.$refs.file.files];
      this.uploadFile();
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // console.log("dragleave",event)
      // Add some visual fluff to show the user can drop its files
    },
    dragleave(event) {
      event.preventDefault();
      // console.log("dragleave",event)
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    showBrowseComputer() {
      $("#file-selector").click();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    showModalDelete() {
      this.isModalDeleteConfirmVisible = true;
    },
    closeModalDelete(){
      this.isModalDeleteConfirmVisible = false;
    },
    loadDocument(document) {
      const json_str_form = CookieHelper.getFlag('fileData')
      let filesData = json_str_form !== undefined ? JSON.parse(json_str_form) : '';
      this.filename = document ? document : filesData?.fileName;

      if (this.filename.trim().length>11){
          if (window.innerWidth < 320) {
            this.filename_tuncated_mobile = this.filename.slice(0, 11) + "..."; 
          } else if (window.innerWidth < 375) {
            this.filename_tuncated_mobile = this.filename.slice(0, 14) + "..."; 
          }
          else {
            this.filename_tuncated_mobile = this.filename.slice(0, 20); 
          }
      }else if (this.filename.trim().length>20){
        this.filename_tuncated_mobile = this.filename.slice(0, 20)+"...";
      }else{
        this.filename_tuncated_mobile = this.filename;
      }

      if (this.filename.trim().length>32){
        this.filename_tuncated_tab = this.filename.slice(0, 32)+"...";
      }else{
        this.filename_tuncated_tab = this.filename;
      }

      if (this.filename.trim().length>48){
        this.filename_tuncated_dektop = this.filename.slice(0, 48)+"...";
      }else{
        this.filename_tuncated_dektop = this.filename;
      }

      this.closeModal();
      $('.first-page').hide();
      $('.search-box-main').show();
      this.initData();
    },
    deleteDocument(document){
      console.log('document',document);
      this.docToDelete = document;
      // this.closeModal();
      this.showModalDelete();
    },
    confirmDeleteDocument(doc){
      let mydocuments = CookieHelper.getFlag("documents", "");
      console.log("myDocuments:",mydocuments,"doc:",doc)
      if (mydocuments != undefined && mydocuments != '') {
        let arr_mydocu = JSON.parse(mydocuments)
        let index = arr_mydocu.indexOf(doc);
        if (index !== -1) {
          arr_mydocu.splice(index, 1);
          let json_str = JSON.stringify(arr_mydocu);
          CookieHelper.setCookie("documents", json_str, 30);
          location.reload();
        }
      }
    },
    async uploadFile() {

      this.loading = true; // Show loader
      await this.simulateProgress(2);
      setTimeout(() => {
        this.showDisclaimer = true;
      }, 5000)
      $(".drag-box-main").addClass("disable-div");

      this.file = this.$refs.file.files[0];
      this.filename = this.file.name.replace(' ', '');

      let arr_mydocu = [];
      let mydocuments = CookieHelper.getFlag('documents')
      if (mydocuments != undefined && mydocuments != '') {
        arr_mydocu = JSON.parse(mydocuments)
        let doc_ctr = this.getLastOccurrence(arr_mydocu, this.filename)
        if (doc_ctr > 1) {
          this.filename = this.filename.replace(".", "_" + doc_ctr + ".");
        }
      }

      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('filename', this.filename);
      formData.append('userid', this.userid);
      formData.append('action', 'upload');
   
      const headers = {'Content-Type': 'application/x-www-form-urlencoded'};
      let upload_status = 0;
      let upload_message = '';

      this.filestatus = 'Uploading File...';
  
      try{
      let res = await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', formData, {headers})
        upload_status = res.data.success;
        upload_message = res.data.message;
      
      if (upload_status != '1') {
        $(".drag-box-main").removeClass("disable-div");
        if (upload_message=='upgrade'){
          window.location.href = process.env.VUE_APP_REDIRECT_UPGRADE_URL;
          return;
        }else if(upload_message=='login'){
          this.filestatus = 'Upload Failed. Please re-login.';
          return;
        }else if(upload_message=='relogin'){
          this.filestatus = 'Upload Failed. Please re-login.';
          return;
        }else{
          this.filestatus = 'Failed to upload';
          return;
        }
      }

      let formEmbd = new FormData();
      formEmbd.append('filename', this.filename);
      formEmbd.append('userid', this.userid);
      formEmbd.append('action', 'create');

      this.addFormValueInCookie(
          { fileName: this.filename, userid: this.userid, action: 'create' }, 
          this.isFileExist
      );

      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};
      const redirect_url = process.env.VUE_APP_REDIRECT_URL || 'https://chatpdf.ai-pro.org/';
      this.formatFileName(this.filename);

      this.filestatus = 'Uploading...';
      await this.simulateProgress(90);
      res = await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', formEmbd, {headersEmbd})
        if (res.data.success == '1') {   
            window.top !== window.self 
              ? await this.simulateProgress(99) 
              : await this.simulateProgress(100);

            if (window.top !== window.self) {
              this.saveDocstToCookie(this.filename);
              window.top.location.href = redirect_url;
              return; 
            }
            $('.first-page').hide();
            $('.search-box-main').show();
            this.initData();
            this.saveDocstToCookie(this.filename);
        } else {
          $(".drag-box-main").removeClass("disable-div");
          this.filestatus = 'Failed to process';
        }
        this.loading = false; // Hide loader
      }catch (error) {
        console.error(error);
        this.loading = false;
        this.filestatus = 'An error occurred during the upload process.';
      }
    },
    saveDocstToCookie(fileName){
      let arr_mydocu = [];
      let mydocuments = CookieHelper.getFlag('documents')
      if (mydocuments != undefined && mydocuments != '') {
        arr_mydocu = JSON.parse(mydocuments)
      }
      if (!arr_mydocu.includes(fileName)) {
        arr_mydocu.push(fileName)
      }

      var json_str = JSON.stringify(arr_mydocu);
      CookieHelper.setCookie("documents", json_str, 30);
      console.log('processing success')
    },
    formatFileName(fileName){
      if (fileName.trim().length>11){
          if (window.innerWidth < 320) {
            this.filename_tuncated_mobile = fileName.slice(0, 11) + "..."; 
          } else if (window.innerWidth < 375) {
            this.filename_tuncated_mobile = fileName.slice(0, 14) + "..."; 
          }
          else {
            this.filename_tuncated_mobile = fileName.slice(0, 20); 
          }
      }else{
        this.filename_tuncated_mobile = fileName;
      }

      if (this.filename.trim().length>32){
        this.filename_tuncated_tab = fileName.slice(0, 32)+"...";
      }else{
        this.filename_tuncated_tab = fileName;
      }

      if (this.filename.trim().length>48){
        this.filename_tuncated_dektop = fileName.slice(0, 48)+"...";
      }else{
        this.filename_tuncated_dektop = fileName;
      }
    },
    getLastOccurrence(docs, filename) {
      let counter = 1;
      for (let i = 0; i < docs.length; i++) {
        let file_list = docs[i].split('.');
        let file = file_list[0].split('_');
        let file_raw = filename.split('.');
        if (file[0] == file_raw[0]) {
          console.log('xxx');
          counter += 1;
        }
      }
      return counter;
    },
    appendToChat(response, rowid) {
     
      let previousChats = $(".result-box").html()
      $('.prompt').val('');
      $(".prompt").attr("disabled", "disabled");
      $(".submit").attr("disabled", "disabled");

      let chatToAppendHuman = '<div class="chat-row human"><div class="message">' + response + '</div></div>'
      let chatToAppendAi = '<div class="chat-row ai"><div class="message" id="' + rowid + '"></div></div>'
      $(".result-box").html(previousChats + chatToAppendHuman + chatToAppendAi)
      $('#' + rowid).closest('.message').css({
        'border-radius': '19px', 
        'overflow': 'hidden'     
      });
      $('#' + rowid).html($('.loader-box').html());

      // Call scrollToBottom after modifying chatToAppendAi
      this.scrollToBottom();

      // Watch for changes in the content of message div
      let observer = new MutationObserver(() => {
        // Scroll to bottom whenever the content changes
        this.scrollToBottom();
      });
      observer.observe(document.getElementById(rowid), { childList: true, subtree: true });
    },
    appendToChatInitial(rowid) {
      console.log(rowid)
      let previousChats = $(".result-box").html()
      $('.prompt').val('');
      $(".prompt").attr("disabled", "disabled");
      $(".submit").attr("disabled", "disabled");

      let chatToAppendAi = '<div class="chat-row ai"><div class="message" id="' + rowid + '"></div></div>'
      $(".result-box").html(previousChats + chatToAppendAi)
      console.log(chatToAppendAi)

      $('#' + rowid).closest('.message').css({
        'border-radius': '19px', 
        'overflow': 'hidden'     
      });

      $('#' + rowid).html($('.loader-box').html());

    },
    closePDF() {
      if(this.isFileExist){
        this.isFileExist = false;
        this.addFormValueInCookie({}, this.isFileExist)
        $('.search-box-main').hide();
        $('.first-page').show();
        location.reload();
      }else{
        location.reload();
      }
    },
    typeWriter(cb = () => {}) {
      if (this.typeWriterCounter < this.paramResponse.length) {
        let currentChar = this.paramResponse.charAt(this.typeWriterCounter);

        if (currentChar === '|') {
          let lastContent = document.getElementById(this.paramRowID).innerHTML;
          if (!lastContent.endsWith('<br>')) {
            document.getElementById(this.paramRowID).innerHTML += '<br>';
          }
        } else {
          document.getElementById(this.paramRowID).innerHTML += currentChar;
        }

        this.typeWriterCounter++;
        setTimeout(() => this.typeWriter(cb), 20);
      } else {
        setTimeout(function() {
          $(".clsExport").removeClass("disable-div");
        }, 3000);

        this.typeWriterCounter = 0;
        cb();
      }
    },
    enableChatBoxAndSendButton() {
      $(".prompt").removeAttr("disabled");
      $(".submit").removeAttr("disabled");
    },
    async initData() {
      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};
      const json_str_form = CookieHelper.getFlag('fileData')
      let filesData = json_str_form !== undefined ? JSON.parse(json_str_form) : '';
      if(json_str_form === undefined) this.addFormValueInCookie({},this.isFileExist)
      this.isFileExist = json_str_form !== "" && json_str_form !== undefined ? filesData.isFileExist : this.isFileExist;
      
      if (this.filename === '' && Boolean(filesData.isFileExist) === false && 
          !this.isFileExist) {
        return;
      }

      let rowid = 0;
      const questions = 'Provide the top 3 questions I can ask that are directly related to the provided context or uploaded document, and it should pertain to the topic of [TOPIC_NAME]. Do not ask questions that are unrelated to the content.';
      let searchEmbd2 = new FormData();

      if (this.isFileExist) {
        this.formatFileName(filesData?.fileName);
        this.addFormValueInCookie({},false)
        $('.first-page').hide();
        $('.search-box-main').show();
        this.filename = filesData?.fileName ? filesData?.fileName : '';
        this.userid = filesData?.userid ? filesData?.userid : '';
        searchEmbd2.append('filename', this.filename);
        searchEmbd2.append('userid', this.userid);
        searchEmbd2.append('prompt', questions);
        searchEmbd2.append('action', 'search');
        searchEmbd2.append('template', '0');
      }else{
        searchEmbd2.append('filename', this.filename);
        searchEmbd2.append('userid', this.userid);
        searchEmbd2.append('prompt', questions);
        searchEmbd2.append('action', 'search');
        searchEmbd2.append('template', '0');
      }

      rowid = Math.floor(100000000 + Math.random() * 900000000);
      this.appendToChatInitial(rowid)

      $(".clsExport").addClass("disable-div");

      await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', searchEmbd2, {headersEmbd}).then((res) => {
        if (res.data.success == '1') {
          this.paramResponse = 'Here are some sample questions you can ask:|' + res.data.data;
          this.paramRowID = rowid
          this.typeWriterCounter = 0;
          $('#' + rowid).html('');
          this.clearLoaderAndRestoreStyles(rowid);
          this.typeWriter(this.enableChatBoxAndSendButton);
        } else {
          if (res.data.message=='upgrade'){
            window.location.href = process.env.VUE_APP_REDIRECT_UPGRADE_URL;
            return;
          }else if(res.data.message=='login'){
            $('#' + rowid).html('Please re-login.');
          }else if(res.data.message=='relogin'){
            $('#' + rowid).html('Please re-login.');
          }else{
            $('#' + rowid).html(res.data.data);
          }
        }
      });

      this.enableChatBoxAndSendButton();
    },
    async searchFile() {
      let prompt = $('.prompt').val().trim();
      
      if (prompt == '') {
        return;
      }

      /***************/



      const TPLogicRun = window.TPLogicRun;
      console.log("TPLogicRun", typeof TPLogicRun);
      if (typeof TPLogicRun === 'function') {
        TPLogicRun();
      } else {
      console.error("TPLogicRun is not a function");
      }


      btutil_getChatUsage();
      let maxTokens = btutilCommon_getCookie("mucnxwlyxt");
      if (maxTokens==='1'){
        return;
      }

      /***************/

      let searchEmbd = new FormData();
      searchEmbd.append('filename', this.filename);
      searchEmbd.append('userid', this.userid);
      searchEmbd.append('prompt', prompt);
      searchEmbd.append('action', 'search');
      searchEmbd.append('ipdflu', this.userIp);
      searchEmbd.append('aiwp_logged_in', CookieHelper.getCookie("aiwp_logged_in"));
      searchEmbd.append('WcvYPABR', CookieHelper.getCookie("WcvYPABR"));

      searchEmbd.append('temperature', this.temperature);
      searchEmbd.append('template', this.template);

      const headersEmbd = {'Content-Type': 'application/x-www-form-urlencoded'};

      let rowid = Math.floor(100000000 + Math.random() * 900000000);

      this.appendToChat(prompt, rowid);
      $(".clsExport").addClass("disable-div");
      let askAI = true;

      // await axios.post(process.env.VUE_APP_API_URL + 'moderations', searchEmbd, {headersEmbd}).then((res) => {
      //   if(res.data.results[0].flagged) {
      //     askAI = false;
      //     this.paramResponse = "I'm sorry, but I can't assist with that. We want everyone to use our tool safely and responsibly. If you have any other questions or need advice on a different topic, feel free to ask.";
      //     this.paramRowID = rowid
      //     this.typeWriterCounter = 0;
      //     $('#' + rowid).html('');
      //     this.typeWriter(this.enableChatBoxAndSendButton);
      //   }
      // }).catch((error) => {
      //   console.log(error);
      //   askAI = false;
      //   this.paramResponse = "Something went wrong. Please try again later.";
      //   this.paramRowID = rowid
      //   this.typeWriterCounter = 0;
      //   $('#' + rowid).html('');
      //   this.typeWriter(this.enableChatBoxAndSendButton);
      // });

      if(askAI) {
        await axios.post(process.env.VUE_APP_API_URL + 'chatpdf', searchEmbd, {headersEmbd}).then((res) => {
          if (res.data.success == '1') {
            this.paramResponse = res.data.data;
            this.paramRowID = rowid
            this.typeWriterCounter = 0;
            $('#' + rowid).html('');
            this.clearLoaderAndRestoreStyles(rowid);
            this.typeWriter(this.enableChatBoxAndSendButton);
            //
            let usage = {};
            let enc = null;
            const DEFAULT_MODEL = process.env.DEFAULT_MODEL || 'gpt-4o-mini';
            try {
              enc = encodingForModel(DEFAULT_MODEL);
              usage.prompt_tokens = (enc.encode(prompt)).length;
              usage.completion_tokens = (enc.encode(this.paramResponse)).length;
              usage.total_tokens = usage.prompt_tokens + usage.completion_tokens;
              usage.model = DEFAULT_MODEL

              // TOKEN USAGE
              btutil_setChatUsage('chatpdf', usage.prompt_tokens, usage.total_tokens, usage.model);
            } catch (e) {
              console.log('Error encoding prompt text', e);
            }
          } else {
            if(res.data.status == "limited"){
              btutil_modalRegisterUpgrade();
            }
            $('#' + rowid).html(res.data.data);
            $(".clsExport").removeClass("disable-div");
            this.enableChatBoxAndSendButton();
          }
        }).catch((error) => {
          if (error.response && error.response.status === 429) {
            this.paramResponse = 'Sorry, too many requests. Please try again in a bit!';
            this.paramRowID = rowid
            this.typeWriterCounter = 0;
            $('#' + rowid).html('');
            $('#' + rowid).addClass('italic');
            this.clearLoaderAndRestoreStyles(rowid);
            this.typeWriter(this.enableChatBoxAndSendButton);
          } else {
            console.log('Error', error.message);
          }

          console.log(error.config);
        });
      }
    },
    clearLoaderAndRestoreStyles(rowid) {
      let messageElement = document.getElementById(rowid)?.closest('.chat-row.ai .message');

      if (messageElement) {
        messageElement.innerHTML = ''; 

        $(messageElement).css({
          'border-radius': '8px', 
          'overflow': 'hidden'
        });
      }
    },
    addFormValueInCookie({ fileName, userid, action }, FileExist){
      let docsData;

      if (window.top !== window.self) {
        this.isFileExist = true;
        docsData = {
        fileName: fileName,
        userid: userid,
        action: action,
        isFileExist: this.isFileExist,
      };

        const json_str_form = JSON.stringify(docsData);
        CookieHelper.setCookie("fileData", json_str_form, 30);
      }else{

      this.isFileExist = FileExist;

      const existingData = CookieHelper.getCookie("fileData");

      if(existingData){
        docsData = JSON.parse(existingData);
      }else{
        docsData = {
          fileName, 
          userid, 
          action,
          isFileExist: this.isFileExist,
        };
      }
      docsData.isFileExist = this.isFileExist;
      const json_str_form = JSON.stringify(docsData);
        CookieHelper.updateCookie("fileData", json_str_form, 30)
      }
    },

    changeUrl() {
        const back_btn = document.getElementById("back_btn");
        const referrer = document.referrer;
        const invalid_referrer = referrer === '' || referrer === window.location.href;

        if (invalid_referrer || window.self !== window.top) {
          if (back_btn){
            back_btn.style.display = "none";
          }
        }

        if (!invalid_referrer){
          const a_dsb_link = document.getElementById('dsb_url');
          const referrer_url = new URL(referrer);
          a_dsb_link.href = referrer_url.origin +'/my-account';
        }
    },

    responseToExport(){
      let response_to_export = document.querySelector(".result-box");

      if (response_to_export) {
        let response = '';
        for(var i = 0; i < response_to_export.children.length; i++) {
          let profile = "";
          if(response_to_export.children[i].classList.contains("ai")) {
            profile = profile + "<div style='margin-bottom: 10px'><b>[ChatPDF]</b> "+ response_to_export.children[i].querySelector(".message").innerHTML + "</div>";
          } else {
            profile = profile + "<div style='margin-bottom: 10px'><b>[User]</b> "+ response_to_export.children[i].querySelector(".message").innerHTML +"</div>";
          }
          response = response + profile;
        }
        return response;
      }
    },

    onClickExportButton(){
      let modal = document.querySelector(".export-modal-container");
      const response = this.responseToExport();
      if (!response) return;

      if (!modal) {
        const btutil_buildExportModal = window.btutil_buildExportModal;

        modal = btutil_buildExportModal(this.onClickExportToPDF);
        document.body.appendChild(modal);
      }
      modal.classList.add("active");
    },

    onClickExportToPDF(){
      let response = this.responseToExport();
      generatePDF(response, "export");
    },

    async getUserIP() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error); 
        return '';
      }
    },
  }
}
</script>
<style>
  .footer {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    margin: 0;
    bottom: 0;
    max-width: none;
    display: flex;
  }
  .first-page {
    padding-top: 12rem;
  }

  .footer .disclaimer {
      padding: 10px 20px;
      font-size: 12px;
      text-decoration: underline;
      color: #515051;
      margin: 0 auto;
  }
/* .seventy {width: 70%;} */
/* .twenty {width: 25%;} */
.upload_btn {
  background: #4285f4;
  color: #fff;
  padding: 10px 15px;
  font-weight: 700;
  margin: 15px auto;
  display: block;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  font-size: 16px;
}
 .clsExport {
  display: inline-block;
  background-color: #06603f;
  width: 168px;
  border: 1px solid #06603f;
  border-radius: 5px;
  height: 25px;
  padding-top: 5px;
  margin-right: 10px;
  float: left;
  color: #fff;
  cursor: pointer;
 }

 .clsExport:hover {
  background-color: #35aa4f;
 }

.ToolbarButtonExport {
  display: inline-block;
  color: #fff;
}

.clsExportText {
  display: inline-block;
  color: #fff;
  margin-left: 10px;
}

.clsClose {
  display: inline-block;
}

.clsCloseX{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  border-style: solid;
  font-weight: bold;
  font-family: sans-serif;
  padding: 2px 4px 0px 4px;
  right: 20px;
  float: right;
  text-align: center;
  cursor: pointer;
}
.clsCloseX svg{
  color: #cfcfcf;
}
.clsCloseX:hover{
  background-color: rgb(210, 210, 210)
}
.label_desktop {
    display: block;
  }
  .label_tab {
    display: none;
  }
  .label_mobile {
    display: none;
  }


@media only screen and (max-width: 1024px) {
  .clsExportText {
    display: none;
  }
  .clsExport {
    width: 40px !important;
  }

  .label_desktop {
    display: none;
  }
  .label_tab {
    display: block;
  }
  .label_mobile {
    display: none;
  }

}

@media only screen and (max-width: 768px) {
  .clsExportText {
    display: none;
  }
  .clsExport {
    width: 40px !important;
  }

  .label_desktop {
    display: none;
  }
  .label_tab {
    display: none;
  }
  .label_mobile {
    display: block;
  }

}


@media only screen and (max-width: 480px) {

  .clsExport {
    /* margin-top: 10px; */
  }
  .title-box .right {
    padding-right: 0px !important;
  }
  .clsClose, .clsCloseX {
    margin-right: 8px;
  }

  .title-box .left {
    padding-left: 9px !important;
    font-size: 16px;
    margin-top: -10px;
  }
  .label_mobile {
    padding-top: 18px !important;
  }
  .img-box img{
    padding-top: 18px !important;
    height: 28px;
    width: auto;
  }
  .search-box-main .prompt {
    width: calc(86% - 50px) !important;
  }
  .search-box-main .submit {
    right: 26px !important;
  }
}
@media only screen and (max-width: 320px) {
  .search-box-main .prompt {
    width: calc(80% - 50px) !important;
  }
}

@media only screen and (max-width: 272px) {
  .clsClose,  .clsCloseX {
    margin-top: 5px;
  }
}

.loader-box {
  display: none;
}

.loader-box-img {
  width: 20px;
}

.container {
  width: 100%;
  height: 100%;
}

.input-file {
  display: none;
}

.filestatus {
  font-size: 12px;
  padding: 0 30px;
  display: block;
  line-height: 1.3;
}

.filename {
  font-size: 12px;
  padding: 0 30px;
  display: block;
  line-height: 1.3;
}

.status-display {
  min-height: 45px;
  /* margin-top: 120px; */
}

/**chat box**/
.chat-row {
  display: flex;
}

.chat-row.ai {
  justify-content: flex-start;
  margin-bottom: 10px;
}

.chat-row.human {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.chat-row.ai .message {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  max-width: 500px;
  text-align: left;
  line-height: 26px;
  font-size: 15px;
  letter-spacing: 0.5px;

}

.chat-row.human .message {
  background-color: #06603f;
  color: #fff;
  padding: 10px;
  max-width: 500px;
  box-shadow: 0 0 3px #d1d2da;
  border-radius: 8px;
  text-align: justify;
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.5px;
}


.search-box-main {
  display: none;
  width: 83%;
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
}

.search-box-main .title-box {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 1px;
  border-color: #dfdfdf;
  border-style: solid;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 19px;
}

.clear {
  clear: both;
}

.title-box .left {
  float: left;
  font-weight: bold;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.title-box .right {
  float: right;
  padding-right: 20px;
  font-size: 14px;
  color: #1677ff;
}
.img-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.right span {
  cursor: pointer;
}

.search-box-main .result-box {
  height: calc(100% - 160px);
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}

.search-box-main .prompt-box {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 9px;
  width: 100%;
  position: relative;
}

.search-box-main .prompt {
  width: calc(100% - 144px);
  height: 36px;
  vertical-align: middle;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 1px;
  border-right: 0px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 8px 66px 8px 16px;
}

.search-box-main .submit {
  width: 38px;
  height: 38px;
  vertical-align: middle;
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 1px;
  border-style: solid;
  border-color: #06603f;
  border-radius: 8px;
  background-color: #06603f;
  padding-top: 5px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 45px;
}

.export-modal svg {
    fill: #06603f !important;
}

.prompt:focus {
  outline: none !important;
  border-color: #a0a0a0;
}
@media only screen and (min-width: 2559px) {
  .first-page {
    padding-top: 20rem;
  }
  .drag-box-main{
    max-width: 20%;
  }
}
@media only screen and (min-width: 1760px) {
  .search-box-main {
    width: 56% !important;
  }
}

@media only screen and (min-width: 1201px) {
  .search-box-main {
    width: 83%;
  }
}
@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .first-page {
    padding-top: 4rem;
  }
  .drag-box-main {
    width: 28% !important;
  }
}
@media only screen and (max-width: 1280px) {
  .first-page {
    padding-top: 8rem;
  }
}
@media only screen and (max-width: 1280px) and (max-height: 720px) {
  .first-page {
    padding-top: 2rem;
  }
  .drag-box-main {
    width: 32% !important;
  }
}
@media only screen and (max-width: 1280px) and (max-height: 720px) {
  .first-page {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 1200px) {
  .search-box-main {
    width: 93%;
  }
}

/**upload box**/
.drag-box-main {
  width: 25%;
  padding: 0px;
  border-radius: 8px;
  margin: 0 auto;
  background: #fff;
  height: 250px;
  position: relative;/**For loader**/
}
@media only screen and (max-width: 1024px) {
  .first-page {
    padding-top: 12rem;
  }
  .drag-box-main {
    width: 40%;
  }
}
@media only screen and (max-width: 800px) and (max-height: 600px) {
  .first-page {
    padding-top: 0rem;
  }
  .drag-box-main {
    width: 44% !important;
  }
  .footer {
    display: block !important;
  }
}
@media only screen and (max-width: 768px) {
  .drag-box-main {
    width: 50%;
  }
}
@media only screen and (max-width: 756px) and (max-height: 500px) {
  .footer {
    display: none !important;
  }
}
@media (max-height: 500px){
  .first-page {
    padding-top: 0;
  }
}
@media (max-height: 481px){
  .footer {
    display: none;
  }
  .first-page {
    padding-top: 0;
  }
}
@media only screen and (max-width: 430px) {
  .first-page {
    padding-top: 8rem;
  }
  .drag-box-main {
    width: 80% !important;
  }
}
@media only screen and (max-width: 379px) and (max-height: 500px) {
  .first-page {
    padding-top: 0rem;
  }
  .drag-box-main {
    width: 90% !important;
  }
}
.drag-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.02);
  width: 100%;
  border: 1px dashed #a0a0a0;
  border-radius: 8px;
  background: #fff;
  height: 100%;
}

.other-options {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.other-options .left {
  float: left;
  cursor: pointer;
  font-size: 14px;
  color: #06603f;
  margin-left: 14px;
}

.other-options .right {
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: #06603f;
  margin-right: 14px;
  margin-bottom: 10px;
}

.first-page h1 {
  color: #000;
  margin-top: 50px;
  margin-bottom: 25px;
}

.drag-text {
  font-size: 15px;
  text-align: center;
  cursor: pointer;
}

.drag-text img {
  width: 60px;
  margin-bottom: 15px;
}

.disable-div {
  pointer-events: none;
}

.italic {
  font-style: italic;
}
#dsb_url,#dsb_url:hover,#dsb_url:visited {
  all: unset;
}
.back-btn-container {
  position: absolute;
  bottom: 0;
  left: 130px;
  top: 56px;
  width: auto;
  height: 80px;
  background-color: transparent;
  color: #000;
}

.back-btn-container h2 {
  z-index: 999 !important;
  margin-top: 0;
  display: inline;
  font-weight: 300;
  padding-left: 15px;
  font-size: 23px;
  position: relative;
}

.back-btn-container p {
  margin-bottom: 0;
}

#back_btn{
  cursor: pointer;
}
#back_btn img{
  vertical-align: bottom;
}

.export-modal-container {
  font-family:'poppins', Arial, Helvetica, sans-serif !important
}
@media screen and (max-width: 945px) {
  .back-btn-container h2 {
       display: none;
  }
  .back-btn-container {
       padding:0;
       width: 50px;
       height: 50px;
       top: 20px;
       left: 0;
  }
}

/* CSS for loader. */
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F6F6F6;
  border-radius: 20px;
  padding: 4px 0px;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 50%;
  background-color: #c1c1c1;
}

.dot:nth-child(1) {
  animation: none; 
}

.dot:nth-child(2) {
  animation: bounce 1.5s infinite ease-in-out;
  animation-delay: 0.2s, 0.2s; 
}

.dot:nth-child(3) {
  animation: bounce 1.5s infinite ease-in-out;
  animation-delay: 0.4s, 0.4s; 
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
}
.progress-container {
  width: 50%;
  background-color: #e0e0e0;
  border-radius: 25px;
  height: 10px;
  overflow: hidden;
  position: relative;
  
}

.progress-bar {
  height: 100%;
  width: 10%; /* Initial progress width */
  background-color: #007d62;
  border-radius: 25px;
  transition: width 0.5s ease;
}

.upload-text {
  font-size: 16px;
  color: #333;
  text-align: center;
}
.upload-text-per{
  font-size: 16px;
  font-weight: bold;
  color: #06603f;
  margin-top: 8px;
  margin-bottom: 5px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
